import './css/accessible.css';
import { COOKIE_DOMAIN, COOKIE_PATH, COOKIE_TIMEOUT } from './constants';

import Vue from 'vue';
import * as Cookies from 'es-cookie';

const COOKIE_KEY = 'acc';

interface AccessibilityAppState {
    accessible: boolean;
}

interface AccessibilityAppMethods extends AccessibilityAppState {
    toggleAccessible: (this: AccessibilityAppState) => void;
    updateStyles: (this: AccessibilityAppState) => void;
}

export const AccessibilityApp = new Vue({
    el: '#accessibility-switch',
    data: function (): AccessibilityAppState {
        return {
            accessible: false
        };
    },
    created(this: AccessibilityAppMethods) {
        const accessibleCookie: string | undefined = Cookies.get(COOKIE_KEY);

        if (accessibleCookie != undefined) {
            this.accessible = accessibleCookie == 'true';
        } else if (window.localStorage && window.localStorage.getItem) {
            try {
                this.accessible = window.localStorage.getItem(COOKIE_KEY) == 'true' || false;
            } catch (error) {
                console.log(error);
            }
        } else {
            this.accessible = false;
        }

        this.updateStyles();

        console.log(`accessible: ${this.accessible}`);
    },
    methods: {
        toggleAccessible(this: AccessibilityAppMethods): void {
            console.log(`accessible: ${this.accessible} -> ${! this.accessible}`);

            this.accessible = ! this.accessible;

            Cookies.set(COOKIE_KEY, `${this.accessible}`, {
                // egy évig nyugodtan megőrizheti a böngésző
                // a felhasználó nem véletlenül állította be
                expires: COOKIE_TIMEOUT, 
                // külső domainekről betöltődő állományok esetén ne küldjük ki
                // máshonnan hozzánk érkező felhasználók esetén viszont szükség van rá
                sameSite: 'lax',
                // állítsuk be az aktuális domain a süti érvényességi határaként
                // így az aldomainekre is érvényes lesz
                // ha nem állítanánk be akkor nem, csak az aktuálisra
                // (lásd a kiválló HTTP süti specifikációban)
                domain: COOKIE_DOMAIN,
                path: COOKIE_PATH
            });
        
            if (window.localStorage && window.localStorage.setItem) {
                try {
                    // cant throw security exception based on policy
                    // let's ingore that
                    window.localStorage.setItem(COOKIE_KEY, `${this.accessible}`);
                } catch (error) {
                    console.log(error);
                }
            }

            this.updateStyles();
        },

        updateStyles(this: AccessibilityAppMethods): void {
            //const defaultStyleNode = document.getElementById("page-style-default") as HTMLLinkElement;
            const accessibleStyleNode = document.getElementById("page-style-accessible") as HTMLLinkElement;
            
            /*if (defaultStyleNode) {
                defaultStyleNode.rel = this.accessible ? 'stylesheet' : 'alternate stylesheet'; // chrome
                defaultStyleNode.disabled = ! this.accessible; // firefox
                if (this.accessible) {
                    defaultStyleNode.title = "default";
                } else {
                    delete defaultStyleNode.title;
                }
            }*/

            if (accessibleStyleNode) {
                accessibleStyleNode.disabled = true; // chromeon először disabled-re kell állítani, hogy utána enabled lehessen ...
                accessibleStyleNode.disabled = ! this.accessible; // firefox
            }
        }
    }
});
